import React from 'react';
import { DocParagraph, DocRow } from '@dev/components';
import { PlxsTextareaWithDropdown } from '@plexus-ui/index';

export default () => {
  const [textValue, setTextValue] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState<any>();

  return (
    <DocRow margin="xxl">
      <DocRow margin="lg">
        <DocRow margin="md">
          <DocParagraph>
            The Textarea component renders a multi line textarea element.
          </DocParagraph>
        </DocRow>

        <PlxsTextareaWithDropdown
          dataTestId=""
          placeholder="Provide instruction document summary"
          onChange={(value) => {
            setTextValue(value.text);
            setSelectedOption(value.selectedOption);
          }}
          value={{
            text: textValue,
            selectedOption: selectedOption,
          }}
          options={[
            { value: 1, label: 'Formal' },
            { value: 2, label: 'Friendly' },
            { value: 3, label: 'Professional' },
            { value: 4, label: 'Conversational' },
            { value: 5, label: 'Persuasive' },
            { value: 6, label: 'Instructive' },
          ]}
          isResizable
        />
      </DocRow>
    </DocRow>
  );
};
