import { makeStyles } from '@material-ui/core/styles';
import { getInputStyle } from '../../styles/input';
import { StyleProps } from './MenuDropdown.types';
import { POPOVER_BOTTOM } from '../_internal/Popover/constants';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '-5px',
    right: 0,
    width: '300px',
  },
  menuWrapper: (props: StyleProps) => {
    const { placement } = props;
    return {
      ...getInputStyle(props),
      margin: 0,
      padding: 0,
      backgroundColor: 'white',
      borderTopWidth: 1,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      borderBottomWidth: 1,
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
    };
  },
  menuList: (props: StyleProps) => ({
    width: '100%',
    maxHeight: props.maxMenuListHeight
      ? `${props.maxMenuListHeight}px`
      : 'initial',
    overflow: 'auto',
    padding: '8px 0',
  }),
}));
