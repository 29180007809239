import { makeStyles } from '@material-ui/core/styles';

import { getTextInputStyles } from '../../styles/input';

import { StyleProps } from './TextareaWithDropdown.types';

const paddingStyles = (props: StyleProps) => {
  if (props.padding === 'sm') {
    return {
      padding: '7px',
    };
  }
  return {};
};

export const useStyles = makeStyles(() => ({
  root: (props: StyleProps) => ({
    ...getTextInputStyles({ isDisabled: props.isDisabled }),
    ...paddingStyles(props),
    padding: '11px 15px',
    minHeight: props.isEmpty ? '42px' : '76px',
    minWidth: '300px',
    position: 'relative',
    resize: props.isResizable ? 'vertical' : 'none',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#E6E6E6',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#808090',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#808090',
    },
  }),
}));
