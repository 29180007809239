import { makeStyles } from '@material-ui/core/styles';

import { defaultTextStyle, getTextStyle } from '../../styles/text';
import { fontColor } from '../../styles';

export const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '5px',
    right: '16px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    backgroundColor: 'inherit',
    borderRadius: 2,
    padding: '3px 8px',
    alignItems: 'center',
  },
  label: {
    ...defaultTextStyle,
    ...getTextStyle({ weight: 'semiBold' }),
    color: fontColor.default,
    overflow: 'hidden',
    paddingLeft: '8px',
    paddingRight: '8px',
    textOverflow: 'ellipsis',
  },
}));
