import React from 'react';
import Popover from '../_internal/Popover';
import MenuList from '../_internal/MenuList';
import { POPOVER_BOTTOM } from '../_internal/Popover/constants';
import MenuItem from '../_internal/MenuItem';

import { useStyles } from './MenuDropdown.styles';

const MenuDropdown = ({
  dataTestId,
  isMenuOpen,
  anchorElement,
  onClickAway,
  onKeyExit,
  onItemSelect,
  options,
  renderOption,
  selectedIndex,
  getOptionLabel,
}) => {
  const classes = useStyles({
    isDisabled: false,
    maxMenuListHeight: 300,
    placement: POPOVER_BOTTOM,
  });

  const newItems = options.map((option, index) => (
    <MenuItem isHighlighted={index === selectedIndex} key={index} value={index}>
      {renderOption ? renderOption(option) : getOptionLabel(option)}
    </MenuItem>
  ));

  return (
    <div className={classes.root}>
      <Popover
        anchorElement={anchorElement}
        dataTestId={`${dataTestId}-popover`}
        isOpen={isMenuOpen}
        onClickAway={onClickAway}
        placement={POPOVER_BOTTOM}
        placementOffset={[0, 0]}
        transitionModifiers={{ in: isMenuOpen, timeout: { enter: 300 } }}
        fullWidth={true}
      >
        <div className={classes.menuWrapper}>
          <div className={classes.menuList}>
            <MenuList autoFocus onKeyExit={onKeyExit} onSelect={onItemSelect}>
              {newItems}
            </MenuList>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default MenuDropdown;
