import React, { ForwardedRef } from 'react';
import PlxsIcon from '../Icon';
import InternalToneBg from '../_internal/ToneBg';
import { useStyles } from './Dropdown.styles';
import MenuDropdown from './MenuDropdown';
import { ComponentProps } from './Dropdown.types';

const Dropdown = React.forwardRef<HTMLDivElement, ComponentProps>(
  (
    { onChange, selectedOption, options },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const classes = useStyles();
    const backgroundTone = 'brand';

    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [anchorElement, setAnchorElement] =
      React.useState<HTMLInputElement | null>(null);

    const handleClickAway = () => {
      setIsMenuOpen(false);
      setAnchorElement(null);
    };

    const handleKeyExit = () => {
      setAnchorElement(null);

      setIsMenuOpen(false);
    };

    const handleItemSelect = (value: number) => {
      setAnchorElement(null);
      setIsMenuOpen(false);

      onChange({
        label: options[value].label,
        value: value,
      });
    };

    const handleClickDropdown = () => {
      setIsMenuOpen(true);
    };

    const renderOption = (option: any) => {
      return option.label;
    };

    const getOptionLabel = (option: any) => option.label;

    return (
      <div ref={ref} className={classes.root}>
        <InternalToneBg hasHover tone={backgroundTone}>
          <div className={classes.button} onClick={handleClickDropdown}>
            <div className={classes.label}>
              {selectedOption?.label ?? options[0].label}
            </div>
            <div>
              <PlxsIcon name="chevronDown" tone="neutral" />
            </div>
          </div>
        </InternalToneBg>
        <MenuDropdown
          dataTestId=""
          isMenuOpen={isMenuOpen}
          anchorElement={anchorElement}
          onClickAway={handleClickAway}
          onKeyExit={handleKeyExit}
          onItemSelect={handleItemSelect}
          options={options}
          renderOption={renderOption}
          selectedIndex={selectedOption?.value ?? 0}
          getOptionLabel={getOptionLabel}
        />
      </div>
    );
  }
);

export default Dropdown;
