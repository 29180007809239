import {
  ActionMenuDocs,
  AutoCompleteDocs,
  ButtonDocs,
  CalloutDocs,
  CheckboxDocs,
  ChipDocs,
  CollapsePanelDocs,
  ConfettiDocs,
  ContentSplashDocs,
  DatePickerDocs,
  DateTimeDocs,
  DialogDocs,
  FieldDocs,
  HeadingDocs,
  HelpLinkDocs,
  IconDocs,
  InputDocs,
  ModalDocs,
  MultiSelectDocs,
  PopoverDocs,
  ProgressBarDocs,
  RadioButtonGroupDocs,
  RichTextContentDocs,
  RichTextEditorDocs,
  SearchDocs,
  SelectDocs,
  SpotlightIconDocs,
  TableDocs,
  TabsDocs,
  TextareaDocs,
  TextareaWithDropdownDocs,
  TextEditorDocs,
  TextFieldDocs,
  TextLinkDocs,
  TileSelectorDocs,
  ToastDocs,
  ToggleButtonDocs,
  TokenProviderDocs,
} from '@dev/docs/index';

const componentList = [
  {
    component: ActionMenuDocs,
    name: 'ActionMenu',
    path: '/actionMenu',
  },
  {
    component: AutoCompleteDocs,
    name: 'AutoComplete',
    path: '/autoComplete',
  },
  {
    component: ButtonDocs,
    name: 'Button',
    path: '/button',
  },
  {
    component: CalloutDocs,
    name: 'Callout',
    path: '/callout',
  },
  {
    component: CheckboxDocs,
    name: 'Checkbox',
    path: '/checkbox',
  },
  {
    component: ChipDocs,
    name: 'Chip',
    path: '/chip',
  },
  {
    component: ConfettiDocs,
    name: 'Confetti',
    path: '/confetti',
  },
  {
    component: CollapsePanelDocs,
    name: 'CollapsePanel',
    path: '/collapsePanel',
  },
  {
    component: ContentSplashDocs,
    name: 'ContentSplash',
    path: '/contentSplash',
  },
  {
    component: DatePickerDocs,
    name: 'DatePicker',
    path: '/datePicker',
  },
  {
    component: DateTimeDocs,
    name: 'DateTime',
    path: '/dateTime',
  },
  {
    component: DialogDocs,
    name: 'Dialog',
    path: '/dialog',
  },
  {
    component: FieldDocs,
    name: 'Field',
    path: '/Field',
  },
  {
    component: HeadingDocs,
    name: 'Heading',
    path: '/heading',
  },
  {
    component: HelpLinkDocs,
    name: 'HelpLink',
    path: '/helpLink',
  },
  {
    component: IconDocs,
    name: 'Icon',
    path: '/icon',
  },
  {
    component: InputDocs,
    name: 'Input',
    path: '/input',
  },
  {
    component: ModalDocs,
    name: 'Modal',
    path: '/modal',
  },
  {
    component: MultiSelectDocs,
    name: 'MultiSelect',
    path: '/Multiselect',
  },
  {
    component: PopoverDocs,
    name: 'Popover',
    path: '/popover',
  },
  {
    component: ProgressBarDocs,
    name: 'ProgressBar',
    path: '/progressBar',
  },
  {
    component: RadioButtonGroupDocs,
    name: 'RadioButtonGroup',
    path: '/radioButtonGroup',
  },
  {
    component: RichTextContentDocs,
    name: 'RichTextContent',
    path: '/richTextContent',
  },
  {
    component: RichTextEditorDocs,
    name: 'RichTextEditor',
    path: '/richTextEditor',
  },
  {
    component: SearchDocs,
    name: 'Search',
    path: '/search',
  },
  {
    component: SelectDocs,
    name: 'Select',
    path: '/select',
  },
  {
    component: SpotlightIconDocs,
    name: 'SpotlightIcon',
    path: '/spotlightIcon',
  },
  {
    component: TableDocs,
    name: 'Table',
    path: '/table',
  },
  {
    component: TabsDocs,
    name: 'Tabs',
    path: '/tabs',
  },
  {
    component: TextareaDocs,
    name: 'Textarea',
    path: '/textarea',
  },
  {
    component: TextareaWithDropdownDocs,
    name: 'TextareaWithDropdown',
    path: '/textareaWithDropdown',
  },
  {
    component: TextEditorDocs,
    name: 'TextEditor',
    path: '/textEditor',
  },
  {
    component: TextLinkDocs,
    name: 'TextLink',
    path: '/textLink',
  },
  {
    component: TextFieldDocs,
    name: 'TextField',
    path: '/textField',
  },
  {
    component: TileSelectorDocs,
    name: 'TileSelector',
    path: '/tileSelector',
  },
  {
    component: ToastDocs,
    name: 'Toast',
    path: '/toast',
  },
  {
    component: ToggleButtonDocs,
    name: 'ToggleButton',
    path: '/toggleButton',
  },
  {
    component: TokenProviderDocs,
    name: 'TokenProvider',
    path: '/tokenProvider',
  },
];

export default componentList;
